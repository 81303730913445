body {
  margin: 0;
  overflow-x: hidden;
  background-color: #1c1c1e;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background: #5fa423;
  background-clip: padding-box;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background-color: #1c1c1e;
}

.rbc-overlay {
  border: none !important;
  background-color: #2d2e32 !important;
  max-height: 200px !important;
  overflow-y: scroll;
  flex-basis: 14.2857%;
  max-width: 14.2857%;
}

.rbc-overlay-header {
  border-bottom: 1px solid #ffffff1f !important;
  color: white;
}
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none !important;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: transparent !important;
}

button.rbc-show-more {
  background-color: transparent;
  font-size: small !important;
}

.rbc-show-more:hover,
.rbc-show-more:focus {
  color: #7e7e7e !important;
}

.rbc-month-row {
  display: table !important;
}
